<template>
  <div>
    <div class="kit__utils__heading">
      <h5>Pricing Tables</h5>
    </div>
    <div>
      <kit-table-8 />
    </div>
  </div>
</template>
<script>
import KitTable8 from '@/components/kit/widgets/Tables/8'

export default {
  components: {
    KitTable8,
  },
}
</script>
